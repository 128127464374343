import validator from 'validator/es';

export default class Validations {
  static ErrorMessageCodes = {
    501: 'Invalid email or password.'
  };

  static checkEmpty(fieldValue) {
    return validator.isEmpty(fieldValue?.trim());
  }

  static checkEmail(email) {
    return !validator.isEmail(email);
  }

  static checkPhone(phone) {
    let validFlag = true;
    const phoneno = /^\d{10}$/;
    if (phone.match(phoneno)) {
      validFlag = false;
    }
    return validFlag;
  }

  static checkPassword(pass) {
    let validFlag = true;
    const passPattern = /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()]).{8,20})$/;
    if (!pass.match(passPattern)) {
      validFlag = false;
    }
    return validFlag;
  }

  static checkEquality(value1, value2) {
    return value1 !== value2;
  }

  static minLength(field, minLength) {
    return field?.length > minLength;
  }

  static oneLCase(pass) {
    if (pass?.length === 0) return true;
    const lowerCase = /^[^a-z]+$/;
    return lowerCase.test(pass);
  }

  static oneUCase(pass) {
    if (pass?.length === 0) return true;
    const upperCase = /^[^A-Z]+$/;
    return upperCase.test(pass);
  }

  static oneNumber(pass) {
    if (pass?.length === 0) return true;
    const oneNumber = /^[^0-9]+$/;
    return oneNumber.test(pass);
  }

  //! @$%^&*
  static oneSpecialChar(pass) {
    if (pass?.length === 0) return true;
    const specialChars = /[!@#$%^&*]+/;
    return !specialChars.test(pass);
  }

  static hasValue(fieldValue) {
    let validFlag = true;
    if (fieldValue && !isNaN(fieldValue) && parseFloat(fieldValue) <= 0) {
      validFlag = false;
    }
    return validFlag;
  }

  static checkEachChildHasQuantity(items, field) {
    let hasQuantity = true;
    items.map((item) => {
      if (item[field] <= 0) {
        hasQuantity = false;
      }
      return item;
    });
    return hasQuantity;
  }

  static checkEachPostItemIsNotEmptyAndHasAValue(items, field, comparativeField) {
    let hasQuantity = true;
    items.map((item) => {
      delete item.qty_error;
      delete item.error;
      if (item[field] === undefined || item[field] === '') {
        hasQuantity = true;
        item.qty_error = true;
      }

      if (item[field] !== undefined) {
        if (parseFloat(item[field]) <= 0 || parseFloat(item[field]) > parseFloat(item[comparativeField])) {
          hasQuantity = false;
          item.error = true;
          item.qty_error = true;
        }
      } else {
        hasQuantity = false;
      }
      return item;
    });
    return hasQuantity;
  }

  static checkEachPostItemHasValidQuantity(items, field, comparativeField) {
    let hasQuantity = true;
    items.map((item) => {
      delete item.qty_error;
      delete item.error;
      if (item[field] === undefined || item[field] === '') {
        hasQuantity = true;
        item.qty_error = true;
      }

      if (item[field] !== undefined) {
        if (parseFloat(item[field]) > parseFloat(item[comparativeField])) {
          hasQuantity = false;
          item.error = true;
          item.qty_error = true;
        }
      } else {
        hasQuantity = false;
      }
      return item;
    });
    return hasQuantity;
  }

  static checkEachChildHasValidQuantity(quantity, items, field, comparativeField) {
    let hasQuantity = true;
    if (parseFloat(quantity) > 0) {
      items.map((item) => {
        delete item.qty_error;
        delete item.error;
        if (item[field] === undefined || item[field] === '') {
          hasQuantity = true;
          item.qty_error = true;
        }
        if (item[field] !== undefined) {
          if (parseFloat(item[field]) <= 0 || parseFloat(item[field]) * parseFloat(quantity) > item[comparativeField]) {
            hasQuantity = false;
            item.error = true;
            item.qty_error = true;
          }
        } else {
          hasQuantity = false;
        }
        return item;
      });
    }
    return hasQuantity;
  }

  static hasInsufficientValue(oldQty, newQuantity, items, field, comparativeField) {
    let hasQuantity = true;
    if (items) {
      items.map((item) => {
        if (item.error) delete item.error;
        return item;
      });
    }
    if (parseFloat(newQuantity) > 0) {
      items.map((item) => {
        let comparativeFieldValue = parseFloat(item[comparativeField]);
        if (oldQty) {
          comparativeFieldValue += parseFloat(item[field]) * parseFloat(oldQty);
        }
        if (hasQuantity && parseFloat(newQuantity) * parseFloat(item[field]) > comparativeFieldValue) {
          hasQuantity = false;
        }
        return item;
      });
    }
    return hasQuantity;
  }

  static maxLength(field, maxLength) {
    let validFlag = true;
    if (field?.length > maxLength) {
      validFlag = false;
    }
    return validFlag;
  }

  static min(field, minValue) {
    let validFlag = true;
    if (field && parseFloat(field) < parseFloat(minValue)) {
      validFlag = false;
    }
    return validFlag;
  }
}
